const React = require("react")
const { ActiveEnv } = require("./config")


// the id and snippetVersion come from hotjar account can be find on https://insights.hotjar.com/site/list 
// the id is the one which let tracking code knows which site it's related
// on the provided tracking code find the hjid for the id and hjsv for the snippetVersion values.
const hotjarSettings = {
  
  development: {
    active: true,
    id: 2103352, // change later with real production data
    snippetVersion: 6,
  },
  production: {
    active: false,
    id: 2103352,
    snippetVersion: 6,
  },
}

exports.onRenderBody = ({ setPostBodyComponents }, pluginOptions) => {
  const settings = hotjarSettings[ActiveEnv];
  if (settings.active) {

    const { id, snippetVersion } = settings;

    // the code below comes from this plugin: https://github.com/pavloko/gatsby-plugin-hotjar/blob/master/src/gatsby-ssr.js 
    return setPostBodyComponents([
      <script
        key='hotjar-tag'
        dangerouslySetInnerHTML={{
          __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${id},hjsv:${snippetVersion}};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `,
        }}
      />,
    ])
  }

  return null;
}
