// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offering-analyze-js": () => import("./../../../src/pages/offering/analyze.js" /* webpackChunkName: "component---src-pages-offering-analyze-js" */),
  "component---src-pages-offering-anti-covid-js": () => import("./../../../src/pages/offering/anti-covid.js" /* webpackChunkName: "component---src-pages-offering-anti-covid-js" */),
  "component---src-pages-offering-deliver-js": () => import("./../../../src/pages/offering/deliver.js" /* webpackChunkName: "component---src-pages-offering-deliver-js" */),
  "component---src-pages-offering-index-js": () => import("./../../../src/pages/offering/index.js" /* webpackChunkName: "component---src-pages-offering-index-js" */),
  "component---src-pages-offering-measure-js": () => import("./../../../src/pages/offering/measure.js" /* webpackChunkName: "component---src-pages-offering-measure-js" */),
  "component---src-pages-platform-alerts-js": () => import("./../../../src/pages/platform/alerts.js" /* webpackChunkName: "component---src-pages-platform-alerts-js" */),
  "component---src-pages-platform-insights-js": () => import("./../../../src/pages/platform/insights.js" /* webpackChunkName: "component---src-pages-platform-insights-js" */),
  "component---src-pages-product-services-check-in-and-out-js": () => import("./../../../src/pages/product-services/check-in-and-out.js" /* webpackChunkName: "component---src-pages-product-services-check-in-and-out-js" */),
  "component---src-pages-product-services-customizablebuttons-js": () => import("./../../../src/pages/product-services/customizablebuttons.js" /* webpackChunkName: "component---src-pages-product-services-customizablebuttons-js" */),
  "component---src-pages-product-services-digital-js": () => import("./../../../src/pages/product-services/digital.js" /* webpackChunkName: "component---src-pages-product-services-digital-js" */),
  "component---src-pages-product-services-limitless-js": () => import("./../../../src/pages/product-services/limitless.js" /* webpackChunkName: "component---src-pages-product-services-limitless-js" */),
  "component---src-pages-product-services-noise-sensor-js": () => import("./../../../src/pages/product-services/noise-sensor.js" /* webpackChunkName: "component---src-pages-product-services-noise-sensor-js" */),
  "component---src-pages-product-services-operations-js": () => import("./../../../src/pages/product-services/operations.js" /* webpackChunkName: "component---src-pages-product-services-operations-js" */),
  "component---src-pages-product-services-people-counter-js": () => import("./../../../src/pages/product-services/people-counter.js" /* webpackChunkName: "component---src-pages-product-services-people-counter-js" */),
  "component---src-pages-product-services-physical-js": () => import("./../../../src/pages/product-services/physical.js" /* webpackChunkName: "component---src-pages-product-services-physical-js" */),
  "component---src-pages-product-services-sentiment-js": () => import("./../../../src/pages/product-services/sentiment.js" /* webpackChunkName: "component---src-pages-product-services-sentiment-js" */),
  "component---src-pages-product-services-touchless-js": () => import("./../../../src/pages/product-services/touchless.js" /* webpackChunkName: "component---src-pages-product-services-touchless-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-resubscribe-js": () => import("./../../../src/pages/resubscribe.js" /* webpackChunkName: "component---src-pages-resubscribe-js" */),
  "component---src-pages-term-and-conditions-js": () => import("./../../../src/pages/term-and-conditions.js" /* webpackChunkName: "component---src-pages-term-and-conditions-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

